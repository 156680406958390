<template>
  <EntityList
    :config="config"
    :columns="columns"
    single-edit
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    :extra-actions="extraActions"
    authority="Poll_Management_Poll"
    desc-sort
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import store from '@/store'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      categoryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'poll',
        endpoint: 'poll',
        route: 'votes',
        title: {
          single: this.$t('Vote Add'),
          plural: this.$t('Votes'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'startDate', label: this.$t('Start Date'), sortable: false },
        { key: 'endDate', label: this.$t('End Date'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'startDate', type: 'datetime' },
        { key: 'endDate', type: 'datetime' },
      ]
    },

    filters() {
      return [
        {
          name: 'category',
          label: this.$t('Category'),
          options: this.categoryOptions,
        },
        {
          name: 'startDate',
          type: 'date',
          label: this.$t('Start Date'),
          maxDateFieldIdx: '2',
        },
        {
          name: 'endDate',
          type: 'date',
          label: this.$t('End Date'),
          minDateFieldIdx: '1',
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
      ]
    },
    extraActions() {
      return [
        {
          callback: 'getViewLink',
          icon: 'PieChartIcon',
          title: 'Results',
          propertyExists: 'ended',
        },
      ]
    },
  },
  mounted() {
    store.dispatch('app-common/fetchPollCategories').then((response) => {
      this.categoryOptions = response.data
    })
  },
}
</script>
